<template>
  <b-container>
    <b-row>
      <b-col v-if="sent">
        <h1>Sent Token</h1>
        <p>
          An email has been sent to you with a time limited link allowing you to view details shared with this email address.
        </p>
        <p>
          Please check your spam folder as well since our email address may not be familliar to your mailbox.
        </p>
      </b-col>
      <b-col v-else-if="error">
        <h1>Oops</h1>
        <p>
          Something has gone a bit sideways...
        </p>
        <p>
          {{ errorMessage }}
        </p>
      </b-col>
      <b-col v-else>
        <h1>Sending Token...</h1>
        <p>Ordering monkey to pound on keyboard randomly...</p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// TODO: inprove the look of this page...
import axios from 'axios'

export default {
  name: 'EmailShareToken',
  components: {},
  data: function() {
    return {
      sent: false,
      error: false,
      errorMessage: "That's interesting..."
    }
  },
  created: function() {
    console.log("email share token page created....call API and set up data");

    console.log("email:" + this.$route.query.email)

    axios.post('email-shares', { email: this.$route.query.email })
      .then(response => {
        console.log("api status: " + response.status + " " + response.statusText)
        console.log('response.data;')
        console.log(response.data)

        if(response.status == 201) {
          this.$data.sent = true
        } else {
          this.$data.error = true
          this.$data.errorMessage = "Can't create token: " + response.statusText
        }
      }, err => {
          this.$data.error = true
          this.$data.errorMessage = "Something went badly wrong - " + err
      })
  }
}
</script>
